import googalinc from "../../Assets/images/googalinc.png";
import mitchaelImg from "../../Assets/images/mitchaelImg.png";

export const UserSubscriptionDetailsdata = {
  startupdetails: "User Subscription Details",
  img1: googalinc,
  username: "Google Inc",
  userid: "User ID #26288",
  EquityOffer: "Subscription Plan",
  EquityOfferValue: "Premium",
  AskPrice: "Cost",
  AskPriceValue: "5,999/month",
  Email: "Email",
  EmailId: "asnsayugub@gmail.com",
  signupdate: "Renewal Date",
  date: "12/06/2022",
  industry: "Purchase Date",
  industryValue: "12/06/2022",
};
