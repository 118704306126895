export const actionTypes = {
  LOGIN_INIT: "LOGIN_INIT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGOUT: "LOGOUT",
  LOGOUT_FAILED: "LOGOUT_FAILED",
  FORGOT_PASSWORD:"FORGOT_PASSWORD",
  FORGOT_PASSWORD_FAILED:"FORGOT_PASSWORD_FAILED",
  INIT_APP: "INIT_APP",
  INIT_APP_FINISH: "INIT_APP_FINISH",
};
