import profileimg from "../../Assets/images/profileimg.png";
const data = [
  {
    username: "Mitchell Williamson",
    userid: "User Id - #26288",
    userimg: profileimg,
    number: "1852",
  },
  {
    username: "Mitchell Williamson",
    userid: "User Id - #26288",
    userimg: profileimg,
    number: "1852",
  },
  {
    username: "Mitchell Williamson",
    userid: "User Id - #26288",
    userimg: profileimg,
    number: "1852",
  },
  {
    username: "Mitchell Williamson",
    userid: "User Id - #26288",
    userimg: profileimg,
    number: "1852",
  },
];
export default data;
